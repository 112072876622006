<template>
  <BaseModal
    name="wpp-lead"
    id="wpp-lead"
    idModal="wpp-lead"
    size="md"
    title="Enviar mensagem WhatsApp"
  >
    <b-row v-if="!loading">
      <b-col cols="12">
        <div class="top-dados">
          <div>
            <p>Insira a mensagem que deseja enviar aos leads selecionados.</p>
          </div>
        </div>
        <b-form data-vv-name="nome">
          <b-row>
            <b-col>
              <b-form-group label="Mensagem">
                <b-form-textarea
                  :placeholder="`Olá, {{nome}}! Tudo bem?`"
                  id="textarea"
                  v-model="mensage"
                  no-resize
                  style="height: 200px !important"
                ></b-form-textarea>
                <b-form-invalid-feedback :state="!errors.has('mensage')">
                  Por favor, insira uma mensagem
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit" :disabled="loading">
        Enviar mensagem
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";

import LeadService from "@/services/resources/LeadService";
const serviceLeads = LeadService.build();

import MensageService from "@/services/resources/MensageService";
const mensageService = MensageService.build();

import moment from 'moment-timezone';

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      mensage: "Olá, {{nome}}! Tudo bem?",
      loading: false,
      leads2: [],
    };
  },
  props: ["leads", "filtros", "type_date", "total"],
  methods: {
    limparCampo() {
      this.mensage = "Olá, {{nome}}! Tudo bem?";
    },
    onSubmit(second) {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.mensage === "") {
            this.$bvToast.toast("Obrigatório preencher o campo de texto", {
              title: "Enviar mensagem WhatsApp",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false;
            return;
          }

          var leadsAlternative = [];
          if (this.leads === "todos") {
            leadsAlternative = "todos";
            this.filtros.campo_data = this.type_date;
            let data = {
              message: this.mensage,
              date_added: moment().format('YYYY-MM-DD HH:mm:ss'),
              filters: this.filtros,
              leads: "todos"
            };

            mensageService
              .create(data)
              .then(() => {
                  this.$bvToast.toast("Mensagem enviada com sucesso", {
                    title: "Enviar mensagem WhatsApp",
                    variant: "info",
                    autoHideDelay: 5000,
                    appendToast: true,
                  });
                  this.$emit("wpp-lead");
                  this.$bvModal.hide("wpp-lead");
                  this.mensage = "Olá, {{nome}}! Tudo bem?";
              })
              .catch((err) => {
                console.log(err);
                this.$bvToast.toast("Erro ao enviar mensagem", {
                  title: "Enviar mensagem WhatsApp",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                this.$bvModal.hide("wpp-lead");
              })
              .finally(() => {
                this.loading = false;
              });
              return;
          } else {
            leadsAlternative = this.leads;
          }

          for (let i = 0; i < leadsAlternative.length; i++) {
            let data = {
              message: this.mensage,
              date_added: moment().format('YYYY-MM-DD HH:mm:ss'),
              id: `send/${leadsAlternative[i]}`,
            };
            mensageService
              .createId(data)
              .then(() => {
                if (i === 0) {
                  this.$bvToast.toast("Mensagem enviada com sucesso", {
                    title: "Enviar mensagem WhatsApp",
                    variant: "info",
                    autoHideDelay: 5000,
                    appendToast: true,
                  });
                  this.$emit("wpp-lead");
                  this.$bvModal.hide("wpp-lead");
                  this.mensage = "Olá, {{nome}}! Tudo bem?";
                }
              })
              .catch((err) => {
                // console.log(err);
                this.$bvToast.toast("Erro ao enviar mensagem", {
                  title: "Enviar mensagem WhatsApp",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                this.$bvModal.hide("wpp-lead");
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }
  .top-dados #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
</style>
