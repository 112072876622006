<template>
  <BaseModal
    name="pipeline-automation"
    id="pipeline-automation"
    idModal="pipeline-automation"
    size="lg"
    title="Automação CRM"
    help="https://gdigital.crisp.help/pt-br/article/mover-leads-para-um-pipeline-991g1k/"
    @shown="openModal()"
  >
    <b-row v-if="!loading">
      <b-col cols="12">
        <div class="top-dados">
          <div>
            <p>Inserir os leads selecionados para o CRM.</p>
          </div>
        </div>
        <b-form data-vv-name="nome">
          <b-row>
            <b-col class="mb-3">
              <b-form-group label="">
                <div class="add-link-body">
                  <label class="mr-1">Selecione um CRM</label>
                  <router-link
                    v-if="!pipelines.length && stop && verificUserLevel"
                    :to="{ name: 'CRM' }"
                    class="add-link"
                    >Deseja criar um CRM?</router-link
                  >
                </div>
                <BaseSelect
                  :selectModel="pipeline"
                  id="ajax"
                  trackname="title"
                  track-by="id"
                  direction="top"
                  placeholder=""
                  selectLabel="Clique para selecionar"
                  deselectLabel="Clique para remover"
                  :array="pipelines"
                  :multiple="false"
                  v-if="showComponentCrm"
                  :searchable="true"
                  @search="debounceCrm($event)"
                  @callback="fetchPipelines($event)"
                  @change="(pipeline = $event), selectedPipeline(pipeline)"
                  :watch="true"
                >
                  <span slot="noOptions">Pesquise pelo crm</span>

                  <span slot="noResult">Oops! Nenhum CRM encontrado.</span>
                </BaseSelect>
                <!-- <BaseSelect
                  trackname="name"
                  :selectModel="pipeline"
                  :array="pipelines"
                  track-by="id"
                  placeholder="Selecione um CRM"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  :allow-empty="false"
                  :searchable="true"
                  @callback="fetchPipelines($event)"
                  @change="(pipeline = $event), selectedPipeline($event)"
                  :watch="true"
                /> -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mb-3">
              <b-form-group label="Selecione uma tag">
                <multiselect
                  label="name"
                  v-model="tag"
                  :options="tags"
                  track-by="id"
                  placeholder="Selecione uma tag"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  :allow-empty="false"
                  :searchable="true"
                  :disabled="!pipeline"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <span
          >Mover
          {{ returnCount() }}
          para o CRM?</span
        >

        <p class="d-block mt-3">
          Aviso: Serão desconsiderados os leads que já estiverem associados a 3
          CRMs diferentes do selecionado
        </p>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit" :disabled="loading">
        Mover
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";
import Vue from "vue";

import LeadService from "@/services/resources/LeadService";
const serviceLeads = LeadService.build();

import MoverLeadsService from "@/services/resources/MoverLeadsService";
import PipelineService from "@/services/resources/PipelineService";

const serviceMoverLeads = MoverLeadsService.build();
const servicePipeline = PipelineService.build();

import TagService from "@/services/resources/TagService";
const serviceTags = TagService.build();

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      tag: {},
      pipeline: null,
      pipelines: [],
      tags: [],
      loading: false,
      leads2: [],
      stop: false,

      //crm
      searchCrm: "",
      stops: [],
      showComponentCrm: true,
    };
  },
  props: ["leads", "filtros", "type_date", "total"],
  computed: {
    verificUserLevel() {
      var user = this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    openModal() {
      this.pipeline = null;
      this.tag = null;
    },
    returnCount() {
      if (this.leads === "todos") {
        return this.total + " Leads Selecionados";
      } else {
        if (this.leads.length === 1) {
          return this.leads.length + " Lead selecionado";
        } else {
          return this.leads.length + " Leads selecionados";
        }
      }
    },
    onSubmit(second) {
      this.loading = true;
      if (this.leads === "todos" && !second) {
        this.fetchLeadsAll();
        return;
      }
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (!this.pipeline) {
            this.$bvToast.toast("Obrigatório selecionar um CRM", {
              title: "Automação CRM",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false;
            return;
          }
          if (!this.tag || this.tag.id === null) {
            this.$bvToast.toast("Obrigatório selecionar uma tag", {
              title: "Automação CRM",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false;
            return;
          }

          this.$bvModal.hide("pipeline-automation");

          let data = {
            pipeline_id: this.pipeline.id,
            tag_id: this.tag.id,
            leads: second ? this.leads2 : this.leads,
            filters: this.filtros,
          };

          Vue.swal({
            title: "Automação CRM",
            text: `Tem certeza que deseja mover os leads selecionados?`,
            type: "confirm",
            showCancelButton: true,
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
            customClass: "sweet-container",
            confirmButtonClass: "button mt-3 mb-3",
            cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
            buttonsStyling: false,
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              serviceMoverLeads
                .create(data)
                .then((response) => {
                  if(response.has_three_associations){
                    this.$bvToast.toast("Leads que já estiverem associados a 3 CRMs serão desconsiderados", {
                      title: "Automação CRM",
                      variant: "danger",
                      autoHideDelay: 5000,
                      appendToast: true,
                    })
                  }else{
                    this.$bvToast.toast("Leads movidos com sucesso", {
                    title: "Automação CRM",
                    variant: "info",
                    autoHideDelay: 5000,
                    appendToast: true,
                    });
                  }
                  this.$emit("pipeline-automation");
                  this.$bvModal.hide("pipeline-automation");
            })
            .catch((err) => {
              // console.log(err);
              this.$bvToast.toast("Erro ao mover leads", {
                title: "Automação CRM",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.loading = false;
            });
            } else {
              this.loading = false
            }
          });
        }
      });
    },
    fetchLeadsAll() {
      this.leads2 = "todos";
      this.onSubmit(true);
    },
    async selectedPipeline(e) {
      this.tag = {};
      this.tags = [];
      var tags = [];

      if (e.id != null) {
        // Utilize Promise.all para aguardar a conclusão de todas as chamadas assíncronas
        await Promise.all(e.tags.map(async (element) => {
          const resp = await serviceTags.read(`/show/${element}`);
          tags.push(resp);
        }));
      }
      // Garante que ficarão na ordem do CRM
      this.tags = e.tags.map((tag) => tags.find((item) => item.id == tag));
    },
    debounceCrm: _.debounce(function (query) {
      if (query === "") {
        this.resetComponentCrm();
      } else {
        this.searchCrm = query;
        this.pipelines = [];
        this.stops = [];
        this.fetchPipelines(1, query);
      }
    }, 500),
    resetComponentCrm() {
      this.searchCrm = "";
      this.pipelines = [];
      this.stops = [];
      this.showComponentCrm = false;
      this.$nextTick(() => {
        this.showComponentCrm = true;
      });
    },
    fetchPipelines(page = 1, query = "") {
      if (this.stops.includes("pipelines")) {
        return;
      }

      var data = {
        page: `${page}&order_by=title&order=ASC&search=${query}`,
      };

      servicePipeline
        .search(data)
        .then((response) => {
          var pipelines = response.data.map((element) => ({
            id: element.id,
            title: element.title,
            tags: element.tags,
          }));

          if (response.data.length < response.per_page) {
            this.stops.push("pipelines");
          }

          this.pipelines = this.pipelines.concat(pipelines);
          this.pipelines = this.pipelines.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((err) => {
          // this.pipelines = [];
        });
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}

span {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }
  .top-dados #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
</style>
