<template>
  <BaseModal
    name="export-leads"
    id="export-leads"
    idModal="export-leads"
    size="md"
    title="Exportação de leads"
    @shown="openModal"
    help="https://gdigital.crisp.help/pt-br/article/exportacao-de-leads-3ru4l0/"
  >
    <b-row v-if="!loading">
      <b-col cols="12">
        <div>
          <label class="mt-1">Dados básicos</label>
          <div class="w-100">
            <div class="d-flex align-items-center">
              <b-form-checkbox
                v-model="nome"
                name="is_main"
                size="lg"
                switch
                class="w-100"
              >
                <p class="info-checkbox mt-2">Nome</p>
              </b-form-checkbox>
              <b-form-checkbox
                v-model="email"
                name="is_main"
                size="lg"
                switch
                class="w-100 switch6"
              >
                <p class="info-checkbox">E-mail</p>
              </b-form-checkbox>
              <b-form-checkbox
                v-model="telefone"
                name="is_main"
                size="lg"
                switch
                class="w-100 switch6"
              >
                <p class="info-checkbox">Telefone</p>
              </b-form-checkbox>
            </div>
          </div>
          <label class="mt-1">Dados de trackeamento</label>
          <div class="d-flex align-items-center">
            <b-form-checkbox
              v-model="tags"
              name="is_main"
              size="lg"
              switch
              class="w-100"
            >
              <p class="info-checkbox mt-2">Tags</p>
            </b-form-checkbox>
            <b-form-checkbox
              v-model="notas"
              name="is_main"
              size="lg"
              switch
              class="w-100 switch6"
            >
              <p class="info-checkbox">Notas</p>
            </b-form-checkbox>
            <b-form-checkbox
              v-model="vendedor"
              name="is_main"
              size="lg"
              switch
              class="w-100 switch6"
            >
              <p class="info-checkbox">Vendedor</p>
            </b-form-checkbox>
          </div>
        </div>
        <b-form data-vv-name="nome">
          <b-row class="mt-3">
            <b-col cols="12">
              <div>
                <label class="mt-1">Dados adicionais </label>
                <BaseSelect
                  :selectModel="selectedAdicionais"
                  placeholder="Selecione os dados adicionais"
                  trackname="meta_key"
                  track-by="id"
                  :array="adicionais"
                  :multiple="true"
                  :taggable="true"
                  class=""
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  @callback="fetchAdicionais($event)"
                  @search="debounceMetas($event)"
                  @change="selectedAdicionais = $event"
                  ><span slot="noResult"
                    >Nenhum dado encontrado.</span
                  ></BaseSelect
                >
              </div>
            </b-col>
            <b-col cols="12">
              <div>
                <label class="mt-3">E-mail para notificar a exportação</label>
                <input
                  v-model="emailDest"
                  class="w-100"
                  type="text"
                  name="email"
                  id="email"
                  placeholder="exemplo@gmail.com"
                />
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit" :disabled="loading">
        Exportar
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";
import AdicionaisService from "@/services/resources/AdicionaisService";
const serviceAdicionais = AdicionaisService.build();
import ExportLeadsService from "@/services/resources/ExportLeadsService";
const serviceExportLeads = ExportLeadsService.build();

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      formulario: null,
      loading: false,
      nome: false,
      email: false,
      telefone: false,
      tags: false,
      notas: false,
      vendedor: false,
      emailDest: "",
      adicionais: [],
      selectedAdicionais: [],
      basico: [],
      trackeamento: [],
      loadingMetas: false,
      stop: false,
    };
  },
  computed: {
    forms() {
      return this.$store.getters.forms;
    },
  },
  props: ["leads", "filtros", 'all_leads'],
  methods: {
    debounceMetas: _.debounce(function (query) {
      if (query === "") {
        this.loadingMetas = false;
      } else {
        this.fetchAdicionais(this.page,query);
      }
    }, 500),
    onSubmit() {
      var emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/g;
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (!this.emailDest) {
            this.$bvToast.toast("Por favor, preencha o campo com um e-mail", {
              title: "Exportação de leads",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false;
            return;
          }

          if (!this.emailDest.match(emailRegex)) {
            this.$bvToast.toast("Por favor, preencha um e-mail válido", {
              title: "Exportação de leads",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false;
            return;
          }

          var adicionais = [];
          if (this.selectedAdicionais.length > 0) {
            for (let i = 0; i < this.selectedAdicionais.length; i++) {
              adicionais.push(this.selectedAdicionais[i]);
            }
          } else {
            adicionais.push("");
          }

          if (this.nome) {
            this.basico.push("name");
          }

          if (this.email) {
            this.basico.push("email");
          }

          if (this.telefone) {
            this.basico.push("phone");
          }

          if (!this.nome && !this.email && !this.telefone) {
            this.basico.push("");
          }

          if (this.tags) {
            this.trackeamento.push("tags");
          }

          if (this.notas) {
            this.trackeamento.push("notas");
          }

          if (this.vendedor) {
            this.trackeamento.push("vendedor");
          }

          if (!this.tags && !this.notas && !this.vendedor) {
            this.trackeamento.push("");
          }

          if(this.leads && this.leads.length && !this.all_leads){
            this.filtros.ids = this.leads;
          } else if (this.leads && this.leads.length) {
            this.filtros.ids = null;
          }

          let data = {
            adicionais: adicionais,
            basico: this.basico,
            email: this.emailDest,
            filters: this.filtros,
            trackeamento: this.trackeamento,
          };
          // console.log("dados aquio ->", data);
          serviceExportLeads
            .create(data)
            .then(() => {
              this.$bvToast.toast("Exportação realizada com sucesso", {
                title: "Exportação de leads",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.selectedAdicionais = [];
              this.$emit("export-leads");
              this.$bvModal.hide("export-leads");
            })
            .catch((err) => {
              this.$bvToast.toast("Erro ao exportar leads", {
                title: "Exportação de leads",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.selectedAdicionais = [];
              this.$bvModal.hide("export-leads");
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    fetchAdicionais(page = 1, query = '') {
      if (this.stop) {
        return;
      }
      let data = {
        page: page,
        search: query
      };
      
      serviceAdicionais.search(data).then((resp) => {
        if (resp.data.length < resp.per_page && query == "") {
          this.stop = true;
          }
        this.adicionais = this.adicionais.concat(resp.data);
        this.adicionais = this.adicionais.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
      });
    },
    openModal() {
      this.formulario = null;
      this.loading = false;
      this.nome = false;
      this.email = false;
      this.telefone = false;
      this.tags = false;
      this.notas = false;
      this.vendedor = false;
      this.emailDest = null;
      this.stop = false;
      this.selectedAdicionais = [];
      this.basico = [];
      this.trackeamento = [];
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }
  .top-dados #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
.switch6 {
  margin-top: 11px !important;
}
</style>
