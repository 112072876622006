<template>
  <BaseModal
    name="seller-automation"
    id="seller-automation"
    idModal="seller-automation"
    size="md"
    title="Automação vendedor"
  >
    <b-row v-if="!loading">
      <b-col cols="12">
        <div class="top-dados">
          <div>
            <p>Inserir o vendedor para os leads selecionados.</p>
          </div>
        </div>
        <b-form data-vv-name="nome">
          <b-row>
            <b-col>
              <b-form-group label="">
                <div class="add-link-body">
                  <label class="mr-1">Selecione um vendedor</label>
                  <router-link
                    v-if="sellers.length === 1 && stop && canShowOption()"
                    :to="{ name: 'Usuarios' }"
                    class="add-link"
                    >Deseja criar um vendedor?</router-link
                  >
                </div>
                <BaseSelect
                  :selectModel="seller"
                  :array="sellers.filter((item) => item.id)"
                  :searchable="true"
                  trackid="id"
                  trackname="email"
                  placeholder="Pesquise pelo e-mail"
                  specificType="sellers"
                  selectLabel=""
                  deselectLabel=""
                  @change="changedType"
                  style="width: 100%"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit" :disabled="loading">
        Salvar
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";

import SellerAutomation from "@/services/resources/SellerAutomation.js";
const serviceSellerAutomation = SellerAutomation.build();

export default {
  components: {
    Multiselect,
  },
  props: ["selected_lead", "filtros", "type_date", "sellers"],
  data() {
    return {
      seller: {},
      loading: false,
      stop: false,
    };
  },
  computed: {
    sellersx() {},
  },
  methods: {
    changedType(seller) {
      this.seller = seller.id;
    },
    canShowOption() {
      let level = this.$store.getters.user.user.level;
      if (level != "blogger" && level != "seller" && level != "templater") {
        return true;
      }

      return false;
    },
    onSubmit() {
      this.loading = true;
      if (this.seller === null || !this.seller || this.seller.id === null) {
        this.$bvToast.toast("Selecione um vendedor", {
          title: "Cadastro manual",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.selected_lead !== "todos") {
            let data = {
              id: "assoc",
              user_id: this.seller,
              leads: this.selected_lead,
            };

            serviceSellerAutomation
              .createId(data)
              .then((response) => {
                this.$bvToast.toast("Vendedor cadastrado com sucesso", {
                  title: "Cadastro manual",
                  variant: "info",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                this.$emit("seller-automation");
                this.seller = { id: null, email: "Selecione um vendedor" };
                this.$bvModal.hide("seller-automation");
              })
              .catch((err) => {
                // console.log(err);
                this.$bvToast.toast("Erro ao cadastrar vendedor", {
                  title: "Cadastro manual",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                this.$bvModal.hide("seller-automation");
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            var data = {
              id: "assoc",
              leads: "todos",
              user_id: this.seller,
              order: "DESC",
              orderby: this.type_date,
              total_pagina: "20",
              filters: this.filtros,
            };

            serviceSellerAutomation.createId(data).finally(() => {
              this.$bvToast.toast("Vendedores cadastrados com sucesso", {
                title: "Cadastro manual",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.$emit("seller-automation");
              this.seller = { id: null, email: "Selecione um vendedor" };
              this.$bvModal.hide("seller-automation");
              this.loading = false;
            });
          }
        }
      });
    },
  },
  created() {},
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }
  .top-dados #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
@media screen and (max-width: 1500px) {
}
</style>
