<template>
  <BaseModal
    name="gerence-company"
    id="gerence-company"
    idModal="gerence-company"
    size="lg"
    @shown="clear"
    title="Gerenciar empresas"
  >
    <b-row v-if="!loading">
      <b-col cols="12">
        <div class="top-dados">
          <div>
            <p>Inserir e remover empresa dos leads selecionados.</p>
          </div>
        </div>
        <b-form data-vv-name="nome">
          <b-row>
            <b-col>
              <b-form-group class="group" label="Selecione uma empresa">
                <BaseSelect
                  direction="top"
                  :selectModel="company"
                  id="ajax"
                  trackname="name"
                  track-by="id"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  open-direction="bottom"
                  :array="companies"
                  :multiple="true"
                  :searchable="true"
                  :loading="loadingCompany"
                  :internal-search="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :options-limit="10"
                  :show-no-results="true"
                  :hide-selected="true"
                  @search="debounceCompany"
                  @callback="getCompanies($event)"
                  @change="company = $event"
                >
                  <span slot="noOptions">Pesquise pela empresa</span>

                  <span slot="noResult">Oops! Nenhuma empresa encontrada.</span>
                </BaseSelect>
                <!-- <multiselect
                  v-model="company"
                  placeholder="Selecione uma empresa"
                  label="name"
                  track-by="id"
                  :options="companies"
                  :multiple="false"
                  :taggable="true"
                  class=""
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  ><span slot="noResult"
                    >Nenhuma empresa encontrada.</span
                  ></multiselect -->
                <!-- > -->
              </b-form-group>
              <b-form-group class="grop" label="O Lead deverá:">
                <div class="d-flex" style="gap: 15px; width: 100%">
                  <BaseButton
                    :variant="action === 'in' ? 'primary' : 'outline-primary'"
                    @click="action = 'in'"
                    style="width: 100%"
                  >
                    Entrar na empresa
                  </BaseButton>
                  <BaseButton
                    :variant="action === 'out' ? 'danger' : 'outline-danger'"
                    @click="action = 'out'"
                    style="width: 100%"
                  >
                    Sair da empresa
                  </BaseButton>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit" :disabled="loading">
        Salvar
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";

// import LeadService from "@/services/resources/LeadService";
// const serviceLead = LeadService.build();

import CompanyService from "@/services/resources/CompanyService";
const serviceCompanies = CompanyService.build();

export default {
  props: ["selected_lead", "filtros", "type_date"],
  name: "gerenceCompany",
  components: {
    Multiselect,
  },
  data() {
    return {
      action: "in",
      company: null,
      companies: [],
      loading: false,

      //company
      loadingCompany: false,
      searchCompany: "",
      stopCompany: "",
    };
  },
  methods: {
    debounceCompany: _.debounce(function (query) {
      if (query === "") {
        this.loadingCompany = false;
      } else {
        this.searchCompany = query;
        this.getCompanies(1, query);
      }
    }, 500),

    async getCompanies(page = 1, query = "") {
      if (this.stopCompany == true) {
        return;
      }
      var data = {
        id: `list?page=${page}&order_by=name&order=ASC&search=${query}`,
      };

      await serviceCompanies
        .read(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page && query == "") {
            this.stopCompany = true;
          }
          this.companies = this.companies.concat(resp.data);
          this.companies = this.companies.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((error) => {})
        .finally(() => {
          this.loadingCompany = false;
        });
    },
    // async getCompanies() {
    //   let data = {
    //     id: "/list",
    //   };
    //   await serviceCompanies
    //     .read(data)
    //     .then((resp) => {
    //       this.companies = resp.data;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     })
    //     .finally(() => {});
    // },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (!this.company.id) {
            this.$bvToast.toast("Selecione uma empresa", {
              title: "Gerenciar empresas",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            return;
          }
          this.loading = true;
          if (this.action === "in") {
            let data = {
              id: `/lead/assoc`,
              company_id: this.company.id,
              leads: this.selected_lead,
              filters: this.filtros,
              orderby: this.type_date,
              total_pagina: "20",
              order: "DESC",
            };

            serviceCompanies
              .createId(data)
              .then(() => {
                this.loading = false;
                this.$bvToast.toast("Empresa associada com sucesso", {
                  title: "Gerenciar empresas",
                  variant: "info",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                this.$emit("fetchLeads");
              })
              .catch((err) => {
                {
                  this.$bvToast.toast("Erro ao associar empresa", {
                    title: "Gestão de empresas",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  });
                }
              })
              .finally(() => {
                this.$emit("gerence-company");
                this.$bvModal.hide("gerence-company");
                this.loading = false;
              });
          }
          if (this.action === "out") {
            let data = {
              id: `/lead/desassoc`,
              company_id: this.company.id,
              leads: this.selected_lead,
              filters: this.filtros,
            };

            serviceCompanies
              .createId(data)
              .then(() => {
                this.$bvToast.toast("Empresa desassociada com sucesso", {
                  title: "Gerenciar empresas",
                  variant: "info",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                this.$emit("gerence-company");
                this.$bvModal.hide("gerence-company");
                this.$emit("fetchLeads");
              })
              .catch(() => {
                this.$bvToast.toast("Erro ao desassociar empresas", {
                  title: "Gerenciar empresas",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    },
    clear() {
      this.action = "in";
      this.company = null;
      this.getCompanies();
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }
  .top-dados #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
.group {
  margin-bottom: 10px !important;
}
</style>
